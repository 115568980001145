<template>
  <div class="row">
    <div v-if="donorType === 'organisation'" class="col-12 mb-3" >
      <TextField type="text" placeholder="Organisation Name" v-model="anonymousValue.organisationName" id="orgNameSadaqah" color="gold" />
    </div>
    <div class="col-6 mb-3">
      <TextField type="text" :placeholder="placeholders.firstName" v-model="anonymousValue.donorName" id="firstNameSadaqah" color="gold" />
    </div>
    <div class="col-6 mb-3">
      <TextField type="text" :placeholder="placeholders.lastName" v-model="anonymousValue.donorSurname" id="lastNameSadaqah" color="gold" />
    </div>
    <div class="col-12 mb-3">
      <TextField type="email" placeholder="Email" v-model="anonymousValue.email" id="usernameSadaqah" color="gold" autocomplete="username" :hasErrors="(donorType === 'individual' && emailExists) ? 1 : 0" @change="checkEmail" />
    </div>
    <UserExistsLogin v-if="donorType === 'individual' && emailExists" />
    <div class="col-12 mb-3">
      <PhoneFieldInt type="tel" :showError="true" v-model:modelValue="anonymousValue.cellNumber" v-model:theError="isValidNumberValue" id="cellZASadaqah" color="gold" ></PhoneFieldInt>
    </div>
    <!-- <div class="col-12 mb-3">
      <div class="row mx-0">
        <div class="col-12 text-center position-relative">
          <div class="form-check form-switch">
            <label class="form-check-label small" for="createDonor">Create a donor profile for faster checkout</label>
            <input class="form-check-input" type="checkbox" id="createDonor" v-model="isCreateDonor">
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-3" v-if="isCreateDonor">
      <TextField type="password" placeholder="Set Password" v-model="password" id="password" color="gold" autocomplete="new-password" :hasErrors="password &&password.length < 6" />
    </div> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Wizard Anonymous Individual',
  components: {
    PhoneFieldInt: defineAsyncComponent(() => import('@/components/PhoneFieldInt.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    UserExistsLogin: defineAsyncComponent(() => import('../components/UserExistsLogin.vue'))
  },
  props: ['anonymous', 'donorType', 'isValidNumber'],
  emits: ['update:anonymous', 'update:isValidNumber'],
  data () {
    return {
      emailExists: false
    }
  },
  watch: {
    donorType () {
      if (this.donorType !== 'organisation') {
        this.checkEmail()
      }
    }
  },
  computed: {
    anonymousValue: {
      get () {
        return this.anonymous
      },
      set (val) {
        console.log('INSDIDE val', val)
        this.$emit('update:anonymous', val)
      }
    },
    isValidNumberValue: {
      get () {
        return this.isValidNumber
      },
      set (val) {
        this.$emit('update:isValidNumber', val)
      }
    },
    placeholders () {
      const obj = {
        firstName: 'First Name',
        lastName: 'Last Name'
      }
      if (this.donorType && this.donorType === 'organisation') {
        obj.firstName = 'C/O First Name'
        obj.lastName = 'C/O Last Name'
      }
      return obj
    },
    isValid () {
      if (this.cell && !this.isValidNumberValue) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    ...mapActions([
      'checkUserExists'
    ]),
    async checkEmail () {
      if (this.donorType !== 'organisation') {
        console.log('CHECK')
        const ret = await this.checkUserExists(this.anonymousValue.email)
        if (ret) {
          if (ret === true) {
            this.emailExists = true
          } else {
            this.emailExists = false
          }
        } else {
          this.emailExists = false
        }
      }
    }
  }
}

</script>
